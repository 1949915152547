<template>
  <div>
    <div class="panel">
     <div class="panel-body text-center">
        <h3>Help Center</h3>
        <hr>
        <p><img src="@/assets/images/big-envelope.svg" height="120" width="120"/></p>
        <p>Our great support team is ready to answer your query. Feel free to contact anytime during the day between 0800 to 1700 GMT+2</p>
        <p>Contact Nelspruit Freight Team on the following details</p>
     </div>
   </div>

    <div class="row">
      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="panel">
          <div class="panel-body">
            <h4><i class="fa fa-map-marker"></i> Our office address</h4>
            <hr>
            <p>14165 Sauer Road</p>
            <p>Gunhill, Harare</p>
            <p>Zimbabwe</p>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="panel">
          <div class="panel-body">
            <h4>Get in touch with us</h4>
            <hr>
            <p><span class="glyphicon glyphicon-phone-alt"></span> Tel <a href="tel:2638644288897">+263 (864) 428 8897</a></p>
            <p><span class="glyphicon glyphicon-phone"></span> Cell <a href="tel:263715861380">+263 715 861 380</a></p>
            <p>Email <a href="mailto:sales@nelspruitfreight.com">sales@nelspruitfreight.com</a></p>
          </div>
        </div>
      </div>

      <div class="col-sm-4 col-md-4 col-lg-4">
        <div class="panel">
          <div class="panel-body">
            <h4><span class="glyphicon glyphicon-clock"></span> Business contact hours</h4>
            <hr>
            <p>Mon-Fri 0800 - 1700 (GMT)</p>
            <p>Sat & Sun (Closed)</p>
            <p>Public And Special Holidays (Closed)</p>
          </div>
        </div>
      </div>
    </div>
    <FooterComponent />
  </div>
</template>

<script type="text/javascript">
  import FooterComponent from "@/components/FooterComponent.vue"

  export default {
    name: "About",
    components: {
      FooterComponent,
    }
};
</script>
