<template>
  <div id="app">
    <app-nav-bar/>
    <div class="container-fluid">
      <router-view />
    </div>
  </div>
</template>

<script type="text/javascript">
import AppNavBar from "@/components/AppNavBar.vue";

export default {
  components: {
    AppNavBar
  }
};
</script>

<style>
@import "./assets/styles/uniservsal_style.css";
</style>
