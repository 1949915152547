<template>
  <div>
    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-md-6">
            <img src="@/assets/images/carriers.jpg" width="100%" height="800px" class="img img-responsive img-rounded">
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-4">
            <h2 style="color: #000000;"><b>LET'S GET STARTED</b></h2>
            <h2 style="color: #000000;">Log In</h2>
            <br>
            <a :href="VUE_APP_LOAD_BOARD_SHIPPER_LOGIN" target="_blank">
              <div class='panel img-rounded panel-login panel-hover'>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h3>Shippers</h3>
                      <p>Quote, book, and track your freight.</p>
                    </div>
                    <div class="col-md-2">
                      <img src="@/assets/images/arrow-right.png" width="100px" height="100px">
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <a :href="VUE_APP_LOAD_BOARD_CARRIER_LOGIN" target="_blank">
              <div class='panel img-rounded panel-login panel-hover'>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h3>Carriers</h3>
                      <p>Find loads and manage trucks.</p>
                    </div>
                    <div class="col-md-2">
                      <img src="@/assets/images/arrow-right.png" width="100px" height="100px">
                    </div>
                  </div>
                </div>
              </div>
            </a>

            <a :href="VUE_APP_LOAD_BOARD_BROKER_LOGIN" target="_blank">
              <div class='panel img-rounded panel-login panel-hover'>
                <div class="panel-body">
                  <div class="row">
                    <div class="col-md-9">
                      <h3>Brokers</h3>
                      <p>Post loads and manage my shipments.</p>
                    </div>
                    <div class="col-md-2">
                      <img src="@/assets/images/arrow-right.png" width="100px" height="100px">
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>

    <FooterComponent/>
  </div>
</template>

<script type="text/javascript">
  import FooterComponent from "@/components/FooterComponent.vue";

  export default {
    components: {
      FooterComponent
    },

    data() {
      return {
        VUE_APP_LOAD_BOARD_CARRIER_LOGIN: process.env.VUE_APP_LOAD_BOARD_CARRIER_LOGIN,
        VUE_APP_LOAD_BOARD_SHIPPER_LOGIN: process.env.VUE_APP_LOAD_BOARD_SHIPPER_LOGIN,
        VUE_APP_LOAD_BOARD_BROKER_LOGIN: process.env.VUE_APP_LOAD_BOARD_BROKER_LOGIN,
      }
    },

    computed: {

    },

    created() {

    },

    methods: {

    }
  };
</script>

<style type="text/css" scoped>
  .panel-login{
    background-color: #f5f4f2;
    color: #000000;
  }

  .panel-hover:hover {
    background-color: #000000;
    color: #ffffff;
  }
</style>
