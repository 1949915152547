<template>
	<div>
		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Inspired and driven by <br> innovation</b></h2>
						<p>Nelspruit Freight provides a marketplace for transportation professionals to engage with pertinent, easy-to-use trucking tools to help truck drivers find loads and increase their loaded miles. We are the most trusted source for freight matching, credit scores, compliance issues, mileage, and routing.</p>
						<br>

						<p>As non-stop innovators, we’re always improving and growing to make Nelspruit Freight the best place for shippers, brokers and carriers to work together. A game-changing investment from Laserstrahlung Capital fuelled our innovation to go even further, imagining the future of freight and developing the product depth our customers need to keep up with today’s demands— and keep ahead of them. As for the future? We’re building it now.</p>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br><br>
						<img src="@/assets/images/company-image.png" width="100%" height="800px" class="img img-responsive img-rounded">
						<br>

						<!-- <p>From humble headquarters in a small-shared office space, we built the first online freight marketplace for Africa and transformed the freight industry. Fast forward to today, we’ve gone way beyond the basic load board, but our focus remains the same: to equip our customers with a toolbox of practical solutions to help them succeed. Today, we deliver software-as-a-solution that support the entire freight-moving lifecycle, from matching to payment, and everything in between.</p> -->
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
				<hr>
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-10 col-md-10 col-lg-10">
						<br><br>
						<h4><b>OUR STORY BY THE NUMBERS</b></h4>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-md-2">
						<h4><b>5+</b></h4>
						<p>years in business</p>
					</div>
					<div class="col-md-2">
						<h4><b>10K+</b></h4>
						<p>annual loads</p>
					</div>
					<div class="col-md-2">
						<h4><b>+1K</b></h4>
						<p>active users</p>
					</div>
					<div class="col-md-2">
						<h4><b>+64K</b></h4>
						<p>active accounts</p>
					</div>
					<div class="col-md-2">
						<h4><b>93%</b></h4>
						<p>customer satisfaction</p>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>		
				<hr>	
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Moving freight and business forward <br> together.</b></h2>
						<p>We believe in doing the right thing. No matter what and how we do business. Especially when it comes to serving others. We are a tight-knit team focused on creating innovative and quality products that drive success for our customers.</p>
						<br>
						<p>We are winners. It’s a safe, fun, and collaborative environment that empowers each other to do big things and achieve more together. We hold each other accountable to be better people whether at work, home, or on the road.</p>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/freight-forwarder.jpg" width="100%" height="800px" class="img img-responsive img-rounded">
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
				<hr>
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-7 col-md-7 col-lg-7">
						<br>
						<h2><b>Sign up and get started.</b></h2>
						<p>We’re here to help you make the most of your logistics and transportation <br> operations</p>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
						<br><br>
						<router-link :to="{ name: 'login' }">
							<button class="btn" style="background: #000000; color: #ffffff;"><strong><b>Login</b></strong> <span class="glyphicon glyphicon-log-in"></span></button>
						</router-link>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1">
						<br><br>
						<router-link :to="{ name: 'register' }">
							<button class="btn btn-primary btn-getstarted"><strong><b>Get started</b></strong></button>
						</router-link>
					</div>
				</div>
			</div>
		</div>
		<FooterComponent/>
	</div>
</template>

<script>
  import FooterComponent from "@/components/FooterComponent.vue";

  export default {
    name: "Company",
    components: {
      FooterComponent
    },

    data: function() {
      return {
        
      }
    }
  };
</script>

<style type="text/css" scoped>
	  .btn-login {
    /*width: 85px;*/
    /*height: 35px;*/
    text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #000000;
    color: #ffffff;
  }

  .btn-getstarted {
     text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #ffffff; 
    color: #000000;
  }
</style>