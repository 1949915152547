<template>
	<div>
	    <div class="jumbotron hero-bkg-animated">
	        <div class="row">
	        	<div class="col-md-6">
	        		<h2 style="color: #ffffff;"><b>Driving efficiency through automation, <br> data and technology</b></h2> 
		        	<p style="color: #ffffff;">We built Nelspruit Freight from the ground up on machine learning and automation technology so your loads get covered faster and more reliably, saving you time and money.</p>
		        </div>
		        <div class="col-md-6"></div>
		    </div>
		    <br>

		    <div class="row">
		        <div class="col-md-2">
		          <span class="pull-left">
	            		<router-link :to="{ name: 'register' }">
	            			<button class="btn btn-get-started" style="background: #000000; color: #ffffff; font-size: 18px;">Get Started</button>
	            		</router-link>
	            	</span>
		        </div>
		    </div>
		    <br><br><br>
	    </div>

	    <div class="row text-center">
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Unmatched efficiency</b></h2>
						<hr>
						<p>Instantly offer shipments to thousands of vetted carriers. Our tech automatically chooses the best truck in minutes, based on location, price, driver performance, batching opportunities, and more.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Industry-leading visibility</b></h2>
						<hr>
						<p>More than 97% of live loads and 100% of drop loads in our network are tracked end-to-end with the Convoy mobile app and smart trailer sensors, providing you with unmatched shipment visibility.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Proactive support</b></h2>
						<hr>
						<p>Automated carrier and shipment monitoring runs safety, compliance, status, and performance checks on every shipment and flags issues to our 24/7 operations team for faster issue resolution.</p>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-md-4">
						<br><br>
						<img src="@/assets/images/image2.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Tech that drives efficiency</b></h2>
						<br>
						<p>Tendering, pricing, and matching happens in minutes, identifying the best, most efficient truck to move every shipment. The result? Better service, lower costs, and reduced empty miles.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>1000+ trucks compete for your loads.</b> Nelspruit Freight instantly offers your loads to thousands of qualified carriers. Carriers bid and compete to win your freight, resulting in market-competitive rates.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>The most efficient match, in minutes.</b> We use machine learning to find you the best truck by proximity, price, OTP likelihood, and more. Should issues arise, our tech can quickly find another truck.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Automated reloads for smarter asset utilization.</b> Our tech identifies opportunities to combine multiple loads into batches, reducing carbon emissions by 45% and driver fall-offs by 11%.</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn btn-get-started" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>More reliable capacity</b></h2>
						<br>
						<p>Nelspruit Freight network has seen 16% fewer crashes compared to the industry, and 99.95% of our loads get delivered without a cargo claim.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Automatic vetting before every load.</b> Every driver in our network is vetted for their operating authority, insurance status, safety, and performance before every load.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Real-time performance monitoring.</b> Our tech monitors shipments and proactively flags issues like a delayed ETA or stopped truck to our 24/7 operations team for fast resolution.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Quality capacity on demand.</b> When we detect an increase in volume on any lane nationwide, our tech instantly alerts our carrier network to boost capacity by up to 40%. The result: More flexibility, faster coverage, and more competitive rates.</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn btn-get-started" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
						<br>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-md-4">
						<br><br>
						<img src="@/assets/images/image3.jpeg" class="img img-responsive img-rounded image-view">
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br><br>
						<img src="@/assets/images/image4.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Hi-Fi Visibility</b></h2>
						<br>
						<p>Get a 360-view of freight markets with the deepest, broadest and most accurate data on rates, capacity, performance and more.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>1,000+ data points on every load.</b> Our 97% carrier app compliance delivers meaningful data and ondemand insights that align transportation teams and improve performance.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Real-time shipment tracking.</b> Map trucks and drop trailers in real time, with GPS location updates every five minutes. Shareable links keep stakeholders in the know.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Facility insights dashboards.</b> Get on-demand access to 40+ key metrics like on-time performance, accessorial spend, dwell time, facility reviews, and more that improve the facility experience and help you become a shipper of choice</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn btn-get-started" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
						<br><br>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Ship sustainably</b></h2>
						<br>
						<p>Every year, empty miles emit more than 87 million metric tons of carbon. Many organizations have goals to reduce carbon emissions — with Nelspruit Freight, you can help your company achieve them.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Sustainable tech.</b> Automated reloads reduce empty miles by 45%. Green Appointment Windows reduce carbon emissions by 36%.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Network optimization.</b> Nelspruit Freight brings the transportation networks of Fortune 500 shippers together, identifying efficiencies through partnership with proven results.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;<b>Measure, report, offset.</b> See your environmental impact through GLEC accredited Scope 3 carbon emissions data and easy-to-pull reporting. Our partnerships offset any remaining emissions.</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn btn-get-started" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br><br>
						<img src="@/assets/images/image5.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>
		<ShipperBottomNavigation/>
		<FooterComponent/>
	</div>
</template>

<script>
  import FooterComponent from "@/components/FooterComponent.vue";
  import ShipperBottomNavigation from "@/components/ShipperBottomNavigation.vue";

  export default {
    name: "Shippers",
    components: {
      FooterComponent,
      ShipperBottomNavigation
    },

    data: function() {
      return {
        
      }
    }
  };
</script>

<style type="text/css" scoped>
  .hero-bkg-animated {
    background: gray
    url("~@/assets/images/ShipperHome.jpg")
    repeat 0 0;
    /*text-align: center;*/
    box-sizing: border-box;
    -webkit-animation: slide 20s linear infinite;
  }

  .hero-bkg-animated h1 {
    font-family: sans-serif;
  }

  @-webkit-keyframes slide {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -400px 0;
    }
  };

  .btn-shippers {
    text-align: center;
    background: #000000; 
    color: #ffffff;
  }

   .learn-more-btn {
    text-align: center;
    background: #000000;
    color: #ffffff;
  }

  .image-view{
  	width: 750px;
  	height: 350px;
  	object-fit: cover;
  }
</style>