import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";

Vue.config.productionTip = false;

new Vue({
  // why is the data not a function here
  data: {
    // global loading using custom loader
    isLoading: false,
    axiosInterceptor: null
  },

  router,
  
  methods: {
    enableInterceptor() {
      (this.axiosInterceptor = axios.interceptors.request.use(config => {
        this.isLoading = true;
        return config;
      })),
        error => {
          this.isLoading = false;
          return Promise.reject(error);
        };

      axios.interceptors.response.use(response => {
        this.isLoading = false;
        return response;
      }),
        error => {
          this.isLoading = false;
          return Promise.reject(error);
        };
    },
    disableInterceptor() {
      // use this method if component should not call the interceptor
      axios.interceptors.request.eject(this.axiosInterceptor);
    }
  },
  render: h => h(App)
}).$mount("#app");
