<template>
	<div>
		<div class="jumbotron hero-bkg-animated">
			<br><br><br>
		    <div style="color: #FFFFFF;">
		        <div class="row">
		        	<h1>Resources</h1>
		            <h4>Learn about the benefits of a digital freight network with these complimentary materials.</h4>
		        </div>
		    </div>
	      <br><br><br><br><br>
	  	</div>
		<div class="row text-center">
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Blogs</h4>
						<p>Go deeper: In-depth articles on industry trends, tips and tricks, technology tools, and hot topics.</p>
						<br><br>
						<a href="#">
							<button class="btn" style="background: #000000; color: #ffffff;">Browse blogs</button>
						</a>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Community</h4>
						<p>Network with like minds in the industry, get to know our products, and share knowledge and ideas.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">Visit our community</button></a>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Webinars</h4>
						<p>Know more, grow more. Take part in panel discussions with industry experts, here to help you succeed.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">Learn more</button></a>
					</div>
				</div>
			</div>
		</div>

		<div class="row text-center">
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Stories</h4>
						<p>See how others in the industry are overcoming obstacles and experiencing success in our case studies.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">View stories</button></a>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Press</h4>
						<p>People are talking. Get in on the buzz.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">Press releases</button></a>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>News</h4>
						<p>What’s happening? Catch up on industry news.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">News stories</button></a>
					</div>
				</div>
			</div>
		</div>

		<div class="row text-center">
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Partners</h4>
						<p>Become a Nelspruit Freight partner. Find the partner program that works for you.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">Learn more</button></a>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Alerts</h4>
						<p>Keep your eyes and ears open. Find and post security alerts.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">Learn more</button></a>
					</div>
				</div>
			</div>
			<div class="col-md-4">
				<div class="panel">
					<div class="panel-body">
						<h4>Emergency freight</h4>
						<p>Info on natural disasters FEMA response, government regulations, and more.</p>
						<br><br>
						<a href="#"><button class="btn" style="background: #000000; color: #ffffff;">Learn more</button></a>
					</div>
				</div>
			</div>
		</div>
		<FooterComponent/>
	</div>
</template>

<script>
  import FooterComponent from "@/components/FooterComponent.vue";

  export default {
    name: "Resources",
    components: {
      FooterComponent
    },

    data: function() {
      return {
        
      }
    }
  };
</script>

<style type="text/css" scoped>
  .hero-bkg-animated {
    background: gray
    url("~@/assets/images/resources.png")
    repeat 0 0;
    /*text-align: center;*/
    box-sizing: border-box;
    -webkit-animation: slide 20s linear infinite;
  }

  .hero-bkg-animated h1 {
    font-family: sans-serif;
  }

  @-webkit-keyframes slide {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -400px 0;
    }
  };

  .resources-btn {
    text-align: center;
    background: #000000;
    color: #ffffff;
  }

  .learn-more-btn {
    text-align: center;
    background: #000000;
    color: #ffffff;
  }
</style>