<template>
  <div>
    <div class="jumbotron hero-bkg-animated">
      <div class="row" style="color: #ffffff;">
        <div class="col-md-8">
          <h2><b>The leading digital freight <br> network</b></h2>
        </div>
      </div>

      <div class="row" style="color: #ffffff;">
        <div class="col-md-6">
          <p>Nelspruit  Freight provides a freight matching web and mobile app marketplace for carriers, brokers and shippers to engage with trucking tools to help professional truck drivers find loads and increase their loaded miles.</p>
        </div>
        <div class="col-md-6"></div>
      </div>
      <br>

      <div class="row">
        <div class="col-md-2">
          <router-link :to="{ name: 'register' }">
            <button class="btn btn-responsive btn-circle-ship-with-us">Ship with us</button>
          </router-link>
        </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <div class="col-md-2">
          <router-link :to="{ name: 'register' }">
            <button class="btn btn-responsive btn-circle-haul-with-us">Haul with us</button>
          </router-link>
        </div>
      </div>
      <br><br><br>
    </div>
    
    <div class="row text-center">
      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <div class="panel">
          <div class="panel-body">
            <h2><b>Reducing shippers' costs</b></h2>
            <hr>
            <p>We save shippers money through automation, flexible capacity, service quality, and better visibility.</p>
            <br>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <div class="panel">
          <div class="panel-body">
            <h2><b>Improving carriers' lives</b></h2>
            <hr>
            <p>We’re dedicated to helping carriers and drivers earn more with less hassle, keeping their trucks full on the lanes they like to run.</p>
          </div>
        </div>
      </div>

      <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
        <div class="panel">
          <div class="panel-body">
            <h2><b>Eliminating carbon waste</b></h2>
            <hr>
            <p>Together we’re creating a more sustainable and responsible supply chain and saving the planet.</p>
            <br>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <h2><b>Tap into your true capacity for <br> growth</b></h2>
            <br>
            <p>With our strategic and simplified capacity sourcing, you can confidently book loads faster, raise your performance, and deliver the service your customers need.</p>
            <ul class="list-unstyled">
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp; Our systems are proven to book open loads up to 15x faster than manual carrier sourcing.</p>
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp; No more relying on existing relationships and “tribal knowledge.” Tap into our infinity pool of contacts and get the job done every time.</p>
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp; With our advanced and automated capacity matching, you’ll never be short on capacity again. No more delays or disappointed customers.</p>
              <!-- <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp; Our systems keep your margins protected, helping you secure optimum rates at cost effective prices for the best possible ROI.</p> -->
            </ul>
            <br>
            <router-link :to="{ name: 'register' }">
              <button class="btn learn-more-btn">Get started</button>
            </router-link>
            <br><br>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <br>
            <img src="@/assets/images/DRONE_CASTAIC_0257_R1-1.jpg.png" width="700px" height="350px" class="img img-rounded img-responsive" style="object-fit: cover;" />
          </div>
          <div class="col-lg-1"></div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <br><br>
            <img src="@/assets/images/LoadBoard2.jpg" width="700px" height="350px" class="img img-rounded img-responsive" style="object-fit: cover;"/>
          </div>
          <div class="col-lg-2"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <h2><b>Industry-leading <br> visibility</b></h2>
            <br>
            <p>Nelspruit Freight’s transportation management solution (TMS) has all of the capabilities needed to synchronize transportation operations, control costs, and enhance service.</p>
            <ul class="list-unstyled">
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Create, edit, and manage agreements with carriers and other logistics services providers. Reduce administration overhead, improve compliance and enhance carrier relationships.</p>
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Leverages advanced algorithms that automatically rate and optimally consolidate shipments across modes to reduce transportation costs and determine delivery dates.</p>
              <!-- <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Streamline and accelerate shipments through digital documentation and automation of the booking, tracking and POD processes using the world's largest multimodal messaging network.</p> -->
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Automate the freight audit process to reduce payment processing costs and improve audit accuracy and reduce discrepancies.</p>
            </ul>
            <br>
            <router-link :to="{ name: 'register' }">
              <button class="btn learn-more-btn">Learn more</button>
            </router-link>
          </div>
          <div class="col-lg-1"></div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-lg-1"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <h2><b>Find the smartest way to run your <br> operation</b></h2>
            <br>
            <p>Our systems convert key performance data into usable insights that can transform the way you work for the better. From your customer service to your carrier relationships, to your level of customer service, Nelspruit Freight’s analytics and intelligence will help you make smarter decisions at every level of your business.</p>
            <ul class="list-unstyled">
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;With insight on everything from supply chain trends to carrier performance, you can continuously identify new ways to raise your profit margins—from connecting with new suppliers, to finding better transport routes in your main operating territories.</p>
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Use business intelligence to assess your operation, and exceed service level expectations to drive greater customer loyalty and satisfaction</p>
              <!-- <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;With a crystal-clear picture of your internal practices and how they impact performance, you can target time-consuming processes, and eliminate them for newfound agility.</p> -->
            </ul>
            <br>
            <router-link :to="{ name: 'register' }">
              <button class="btn learn-more-btn">Get started</button>
            </router-link>
          </div>
          <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <br><br>
            <img src="@/assets/images/DataInsights.jpg" width="100%" height="200px" class="img-rounded img-responsive" style="object-fit: cover;"/>
          </div>
        </div>
      </div>
    </div>

    <div class="panel">
      <div class="panel-body">
        <div class="row">
          <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <br><br>
            <img src="@/assets/images/Compliance.jpg" width="100%" height="200px" class="img-rounded img-responsive"/>
          </div>
          <div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
          <div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
            <h2><b>Get your operating <br> authority</b></h2>
            <br>
            <p>Better protect your business against risk with solutions that go beyond products and software to deliver a dedicated team of compliance experts, an extensive database of more than 98% of active carriers, and ongoing monitoring throughout your relationship with the carrier.</p>
            <ul class="list-unstyled">
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;We make onboarding and monitoring fast and easy, so you can move fast and rest easy.</p>
              <!-- <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Automated Carrier Onboarding makes checking for compliance fast, right from your TMS. Enjoy a custom carrier registration website built to meet your needs. Differentiate yourself to shipper customers with the assurance their cargo is covered.</p> -->
              <p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Automated Carrier Monitoring keeps you in the loop and updated, so you can rest assured you’re covered against risk if a carrier’s compliance status changes.</p>
            </ul>
            <br>
            <router-link :to="{ name: 'register' }">
              <button class="btn learn-more-btn">Learn more</button>
            </router-link>
          </div>
          <div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
        </div>
      </div>
    </div>

    <HomeBottomNavigation/>
    <FooterComponent/>    
  </div>
</template>

<script>
  import FooterComponent from "@/components/FooterComponent.vue";
  import HomeBottomNavigation from "@/components/HomeBottomNavigation.vue";

  export default {
    name: "Homepage",
    components: {
      FooterComponent,
      HomeBottomNavigation
    },

    data: function() {
      return {

      };
    },

    mounted(){

    }
  };
</script>

<style type="text/css" scoped>
  .hero-bkg-animated {
    background: gray
    url("~@/assets/images/front4.jpeg")
    repeat 0 0;
    /*text-align: center;*/
    box-sizing: border-box;
    -webkit-animation: slide 20s linear infinite;
  }

  .hero-bkg-animated h1 {
    font-family: sans-serif;
  }

  @-webkit-keyframes slide {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -400px 0;
    }
  }

  .btn-circle-ship-with-us {
    /*width: 94px;*/
    /*height: 47px;*/
    text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #000000; 
    color: #ffffff;
    font-size: 18px;
  }

  .btn-circle-haul-with-us {
    /*width: 85px;*/
    /*height: 40px;*/
    text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #ffffff; 
    color: #000000;
    font-size: 18px;
  }

  .learn-more-btn {
    text-align: center;
    background: #000000;
    color: #ffffff;
    font-size: 18px;
  }
</style>
