import Vue from "vue";
import VueRouter from "vue-router";
import HelpCenter from "@/views/help-center/HelpCenter.vue";
import NotFound from "@/components/NotFound.vue";
import Security from "@/views/security-and-privacy/Security";
import Privacy from "@/views/security-and-privacy/Privacy";
import TermsAndConditions from "@/views/terms-of-use/TermsAndConditions";
import Contact from "@/views/contact/Contact.vue";
import Homepage from "@/views/home/Homepage.vue";
import Shippers from "@/views/shippers/Shippers.vue";
import Carriers from "@/views/carriers/Carriers.vue";
import Brokers from "@/views/brokers/Brokers.vue";
import Resources from "@/views/resources/Resources.vue";
import Company from "@/views/about/Company.vue";
import Login from "@/views/authentication/Login.vue";
import Register from "@/views/authentication/Register.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: NotFound
  },

  {
    path: "/",
    name: "Homepage",
    component: Homepage
  },

  {
    path: "/login",
    name: "login",
    component: Login
  },

  {
    path: "/register",
    name: "register",
    component: Register
  },
  
  {
    path: "/company",
    name: "company",
    component: Company
  },

  {
    path: "/contact",
    name: "contact",
    component: Contact
  },

  {
    path: "/shippers",
    name: "shippers",
    component: Shippers
  },

  {
    path: "/carriers",
    name: "carriers",
    component: Carriers
  },

  {
    path: "/brokers",
    name: "brokers",
    component: Brokers
  },

   {
    path: "/resources",
    name: "resources",
    component: Resources
  },

  {
    path: "/help-center",
    name: "help-center",
    component: HelpCenter
  },

  {
    path: "/security",
    name: "security",
    component: Security
  },

  {
    path: "/privacy",
    name: "privacy",
    component: Privacy
  },

  {
    path: "/terms-of-use",
    name: "terms-of-use",
    component: TermsAndConditions
  }
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
