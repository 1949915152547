<template>
	<div>
	    <div class="jumbotron hero-bkg-animated">
	        <div class="row">
	        	<div class="col-md-6">
	        		<h2 class="pull-left" style="color: #ffffff;"><b>The power to grow your <br> business</b></h2> 
		        	<p class="pull-left" style="color: #ffffff;">Tap into Nelspruit Freight’s network of over 400,000 vetted trucks and 80,000 high-quality carriers to automatically find the best match for your loads. We handle carrier payments with Nelspruit Freight QuickPay™ at no additional cost.</p>
		        </div>
		        <div class="col-md-6"></div>
		    </div>
		    <br>

		    <div class="row">
		        <div class="col-md-2">
		          <router-link :to="{ name: 'register' }">
		          	<button class="btn btn-get-started" style="background: #000000; color: #ffffff; font-size: 18px;">Get Started</button>
		          </router-link>
		        </div>
		    </div>
		    <br><br><br>
	    </div>

	    <div class="row text-center">
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Reach more carriers, 24/7</b></h2>
						<hr>
						<p>Nearly 30% of our winning bids are made after-hours. With FreightBoard for Brokers, automatically reach carriers every day, at any time—even while you sleep—to make sure you find the right trucks at a competitive cost.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Unlock quality capacity</b></h2>
						<hr>
						<p>Our unique quality and safety model verifies carrier compliance and performance before every load, resulting in 16% fewer crashes and 20X fewer cargo claims vs. the industry average.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Leverage our tech at no cost</b></h2>
						<hr>
						<p>By adding FreightBoard’s proprietary technology to your brokerage, you’ll benefit from real-time visibility into your loads, automatic detention payments, electronic BOLs, and more to save you time and money.</p>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-md-4">
						<br><br>
						<img src="@/assets/images/image6.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Frictionless carrier matching</b></h2>
						<br>
						<p>Match with high-quality carriers up to 130% faster. Our industry-leading carrier safety standards ensure that every load is hauled by an extensively vetted, high-quality carrier. The result? 99.95% of our loads get delivered without a cargo claim.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Quote and book live and drop loads one by one or in batches</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Plan, procure and execute freight in one place.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Real-time spot and contract price comparison.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Manage your own carrier networks with historical lane performance, tendering, tracking and data insights.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;See real-time ETAs and delay alerts, with shareable tracking links</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Give your entire team access to online BOLs and other proof of delivery documents</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Industry-leading visibility</b></h2>
						<br>
						<p>No one else can tell you more. Nelspruit Freight’s Hi-Fi Visibility provides detailed, real-time updates and robust analytics to help you save time and improve the health of your supply chain.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Track your shipments on a live map in real time</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Uncover trends with interactive shipment and facility insights dashboards, visualizing 40+ key metrics</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Leverage carrier feedback and facility reviews to become a shipper of choice</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
						<br><br>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/image7.jpeg" class="img img-responsive img-rounded image-view">
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/load-matching-technology.png" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Load matching at scale</b></h2>
						<br>
						<p>Use our online platform and TMS integrations to quickly flex capacity as your customers’ demand fluctuates. Control carrier selection and confirm loads in as little as five minutes.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Book, track, and manage all of your shipments on one platform</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Get instant, market-based quotes for backup and spot loads</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Utilize visibility integrations with tracking and telematics, compliance and more</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Automate invoicing and other administrative workflows</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Broker Authority</b></h2>
						<br>
						<p>Get your broker business up and running, plus access to tools to grow quickly. The experts at Nelspruit Freight can help answer your questions and guide you through the process.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Get your broker authority the straightforward and simple way.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Never worry about annual application deadlines again.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Start brokering freight quickly and affordably.</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Feel supported every step of the way, even after you’re up and running</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register' }">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
						<br><br>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/BrokerCompliance.jpg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>
		
		<BrokerBottomNavigation/>
		<FooterComponent/>
	</div>
</template>

<script>
  import FooterComponent from "@/components/FooterComponent.vue";
  import BrokerBottomNavigation from "@/components/BrokerBottomNavigation.vue";

  export default {
    name: "Brokers",
    components: {
      FooterComponent,
      BrokerBottomNavigation
    },

    data: function() {
      return {
        
      }
    }
  };
</script>

<style type="text/css" scoped>
  .hero-bkg-animated {
    background: gray
    url("~@/assets/images/BrokerHome.jpg")
    repeat 0 0;
    /*text-align: center;*/
    box-sizing: border-box;
    -webkit-animation: slide 20s linear infinite;
  }

  .hero-bkg-animated h1 {
    font-family: sans-serif;
  }

  @-webkit-keyframes slide {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -400px 0;
    }
  };

  .btn-brokers {
    text-align: center;
    background: #000000; 
    color: #ffffff;
  }

  .learn-more-btn {
    text-align: center;
    background: #000000;
    color: #ffffff;
  }

  .image-view{
  	width: 750px;
  	height: 350px;
  	object-fit: cover;
  }
</style>