<template>
	<div>
		<div class="jumbotron hero-bkg-animated">
	        <div class="row">
	        	<div class="col-md-6">
	        		<h2 class="pull-left" style="color: #ffffff;"><b>Big-time discounts for every carrier <br> on the road</b></h2> 
		        	<p class="pull-left" style="color: #ffffff;">From cutting your operating costs to finding profitable loads quickly, Nelspruit Freight is here to help you navigate your toughest challenges and grow your rates for the long haul.</p>
		        </div>
		        <div class="col-md-6"></div>
		    </div>
		    <br>

		    <div class="row">
		        <div class="col-md-2">
		          <router-link :to="{ name: 'register'}">
		          	<button class="btn btn-get-started" style="background: #000000; color: #ffffff; font-size: 18px;">Get Started</button>
		          </router-link>
		        </div>
		    </div>
		    <br><br><br>
	    </div>

	    <div class="row text-center">
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Maximize earnings</b></h2>
						<hr>
						<p>Drive your business forward by keeping your trucks full on the lanes.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>Reduce hassle</b></h2>
						<hr>
						<p>Make your job easier, cutting down operating costs and boosting profits.</p>
					</div>
				</div>
			</div>
			<div class="col-sm-4 col-md-4 col-lg-4">
				<div class="panel">
					<div class="panel-body">
						<h2><b>See all the loads</b></h2>
						<hr>
						<p>Get equal access to winning freight from the nation’s best shippers.</p>
					</div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/image8.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Get the Best Loads First</b></h2>
						<br>
						<p>From negotiating rates to finding routes, from managing paperwork to monitoring partners, from IFTA reporting to instant load-booking, look to our load board for easy handling of all your needs.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Find, bid on, and win loads—24/7</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Get paid within 24 hours with Nelspruit Freight QuickPay™</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Request hassle-free detention or lumper codes with a single tap</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Bundle your head hauls and backhauls to reduce empty miles</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Save money per truck, per year with TruckYeah Savings offers</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register'}">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Haul without the hassle</b></h2>
						<br>
						<p>See the whole picture and take action. CarrierTMS not only gives you visibility into shipments, weather and traffic analytics, key performance indicators, and possible disruptions— it unlocks the potential to better plan and execute your freight.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Find and manage work right from your phone or computer</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;See shipper and facility names, addresses, and ratings on every load</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Send BOLs through the app to streamline bookkeeping</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Track your fleet’s locations and arrival times with GPS integration</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Get automatic alerts when loads are available on your preferred lanes</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register'}">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
						<br><br>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/image9.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/image10.jpeg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Freight Rate Analytics</b></h2>
						<br>
						<p>Drive growth and increase efficiency with actionable intelligence. From facility insights to comprehensive benchmarking, to weekly KPI reporting on costs, speed, and service, to performance dashboards, and on-demand data network analytics.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;15-day rates based on $1.5 billion in real transactions</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Nationwide supply-and-demand maps with load-to-truck ratios</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Route recommendations for higher rates</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Uncover trends with interactive shipment and facility insights dashboards, visualizing 40+ key metrics</p>
						</ul>
						<br>
						<router-link :to="{ name: 'register'}">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Learn more</button>
						</router-link>
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<div class="panel">
			<div class="panel-body">
				<div class="row">
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<h2><b>Loads for all, but be compliant first</b></h2>
						<br>
						<p>Ready to get your trucking authority? Our authority packages make the process so much easier than going it alone. Carriers of all sizes can sign up and book their first loads in just minutes.</p>
						<ul class="list-unstyled">
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Get free access to tens of thousands of loads</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Haul power-only loads using just your tractor—we’ll supply the trailers</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Secure dedicated freight contracts</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Book it all through the app and overcome the pitfalls of phone negotiations like haggling, language barriers, and cultural biases</p>
							<p><img src="@/assets/images/grey-check.png" width="18" height="18" class="list-arrow">&nbsp;&nbsp;&nbsp;Never worry about annual application deadlines again.</p>
						</ul>
						<br>
						<router-link :to="{ name: 'login'}">
							<button class="btn learn-more-btn" style="background: #000000; color: #ffffff;">Find loads</button>
						</router-link>
						<br><br>
					</div>
					<div class="col-xs-12 col-sm-2 col-md-2 col-lg-2"></div>
					<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
						<br>
						<img src="@/assets/images/CarrierCompliance.jpg" class="img img-responsive img-rounded image-view">
					</div>
					<div class="col-xs-12 col-sm-1 col-md-1 col-lg-1"></div>
				</div>
			</div>
		</div>

		<CarrierBottomNavigation/>
		<FooterComponent/>
	</div>
</template>

<script>
  import FooterComponent from "@/components/FooterComponent.vue";
  import CarrierBottomNavigation from "@/components/CarrierBottomNavigation.vue";

  export default {
    name: "Carriers",
    components: {
      FooterComponent,
      CarrierBottomNavigation
    },

    data: function() {
      return {
        
      }
    }
  };
</script>

<style type="text/css" scoped>
  .hero-bkg-animated {
    background: gray
    url("~@/assets/images/CarrierHome.jpg")
    repeat 0 0;
    /*text-align: center;*/
    box-sizing: border-box;
    -webkit-animation: slide 20s linear infinite;
  }

  .hero-bkg-animated h1 {
    font-family: sans-serif;
  }

  @-webkit-keyframes slide {
    from {
      background-position: 0 0;
    }
    to {
      background-position: -400px 0;
    }
  };

  .btn-get-started {
    /*width: 94px;*/
    /*height: 47px;*/
    text-align: center;
    /*padding: 6px 0;*/
    /*line-height: 1.42;*/
    /*border-radius: 15px;*/
    background: #000000; 
    color: #ffffff;
    font-size: 18px;
  }

  .learn-more-btn {
    text-align: center;
    background: #000000;
    color: #ffffff;
  }

  .image-view{
  	width: 750px;
  	height: 350px;
  	object-fit: cover;
  }
</style>